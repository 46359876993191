<script>
import 'simplelightbox/dist/simple-lightbox.css';
import SimpleLightbox from "simplelightbox";

import {onMounted} from 'vue';

export default {
  name: 'SimpleMap2',
  setup() {

    return {
    }
  }
}
</script>

<template>
    <img src="./d8edc5b9-f96e-40e2-9e31-af5949ae0818.webp" class="img img-fluid" alt="map">
</template>

<style scoped>

</style>