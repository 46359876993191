<script>
import {onMounted, reactive, toRefs, watch} from 'vue';

export default {
  name: 'TicketRegistrationForm',
  props: {
    priceId: {
      type: String,
      required: true
    },
    adultPriceId: {
      type: String,
      required: true
    },
    childPriceId: {
      type: String,
      required: true
    },
    dayId: {
      type: String,
      required: true
    },
    seatId: {
      type: String,
      required: true
    },
    adultCountId: {
      type: String,
      required: true
    },
    childCountId: {
      type: String,
      required: true
    },
    day1Seat0Id: {
      type: String,
      required: true
    },
    day1Seat1Id: {
      type: String,
      required: true
    },
    day3Seat0Id: {
      type: String,
      required: true
    },
    day3Seat1Id: {
      type: String,
      required: true
    },
    actionData: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const data = reactive({
      elements: {
        price: null,
        adultPrice: null,
        childPrice: null,
        day1seat0: null,
        day1seat1: null,
        day3seat0: null,
        day3seat1: null,
        adultCount: null,
        childCount: null,
      },
      price: 0,
      day: null,
      seat: null,
      adultCount: 0,
      childCount: 0,
      priceAdult: null,
      priceChild: null,
    });

    function minZero(value) {
      return value < 0 ? 0 : value;
    }

    onMounted(() => {
      data.elements.price = document.getElementById(props.priceId);
      data.elements.adultPrice = document.getElementById(props.adultPriceId);
      data.elements.childPrice = document.getElementById(props.childPriceId);
      data.elements.day1seat0 = document.getElementById(props.day1Seat0Id);
      data.elements.day1seat1 = document.getElementById(props.day1Seat1Id);
      data.elements.day3seat0 = document.getElementById(props.day3Seat0Id);
      data.elements.day3seat1 = document.getElementById(props.day3Seat1Id);

      data.elements.day1seat0.innerHTML = minZero(props.actionData.days[0].seats[0].totalTickets - props.actionData.days[0].seats[0].usedTickets);
      data.elements.day1seat1.innerHTML = minZero(props.actionData.days[0].seats[1].totalTickets - props.actionData.days[0].seats[1].usedTickets);
      data.elements.day3seat0.innerHTML = minZero(props.actionData.days[1].seats[0].totalTickets - props.actionData.days[1].seats[0].usedTickets);
      data.elements.day3seat1.innerHTML = minZero(props.actionData.days[1].seats[1].totalTickets - props.actionData.days[1].seats[1].usedTickets);

      document.getElementsByName(props.dayId).forEach(day => {
        if (day.checked) {
          data.day = day.value;
        }

        day.addEventListener('change', (e) => {
          data.day = e.srcElement.value;
          calculatePrice();
        });
      });

      document.getElementsByName(props.seatId).forEach(seat => {
        if (seat.checked) {
          data.seat = seat.value;
        }

        seat.addEventListener('change', (e) => {
          data.seat = e.srcElement.value;
          calculatePrice();
        });
      });

      data.elements.adultCount = document.getElementById(props.adultCountId);
      data.adultCount = data.elements.adultCount.value;
      data.elements.adultCount.addEventListener('input', (e) => {
        data.adultCount = parseInt(e.srcElement.value);
        calculatePrice();
      });

      data.elements.childCount = document.getElementById(props.childCountId);
      data.childCount = data.elements.childCount.value;
      data.elements.childCount.addEventListener('input', (e) => {
        data.childCount = parseInt(e.srcElement.value);
        calculatePrice();
      });

      calculatePrice();
    });

    function calculatePrice() {
      data.priceAdult = 0;
      data.priceChild = 0;

      if (data.day === null || data.seat === null) {
        data.price = 0;
        data.priceAdult = null;
        data.priceChild = null;
        return;
      }
      if (data.day == 1) {
        if (data.seat == 2) {
          data.priceAdult = 150;
          data.priceChild = 0;
        } else {
          data.priceAdult = 350;
          data.priceChild = 100;
        }
      } else if (data.day == 3) {
        if (data.seat == 2) {
          data.priceAdult = 100;
          data.priceChild = 0;
        } else {
          data.priceAdult = 250;
          data.priceChild = 100;
        }
      }

      data.price = data.priceAdult * data.adultCount + data.priceChild * data.childCount;

      let proxyDays = [];
      proxyDays[1] = 0;
      proxyDays[3] = 1;
      //nastaveni max limitu inputu
      let totalTickets = props.actionData.days[proxyDays[data.day]].seats[data.seat].totalTickets;
      let usedTickets = props.actionData.days[proxyDays[data.day]].seats[data.seat].usedTickets;

      if(totalTickets === null || usedTickets === null) {
        data.elements.adultCount.removeAttribute('max');
        data.elements.childCount.removeAttribute('max');
      }
      else  {
        let max = totalTickets - usedTickets;
        data.elements.adultCount.max = max;
        data.elements.childCount.max = max;
      }
    }

    watch(() => data.price, (value) => {
      data.elements.price.innerHTML = value;
    });

    watch(() => data.priceAdult, (value) => {
      if (value === null) {
        data.elements.adultPrice.innerHTML = '';
        return;
      }

      data.elements.adultPrice.innerHTML = '/ ' + value + ' Kč';
    });

    watch(() => data.priceChild, (value) => {
      if (value === null) {
        data.elements.childPrice.innerHTML = '';
        return;
      }
      data.elements.childPrice.innerHTML = '/ ' + value + ' Kč';
    });

    return {
      ...toRefs(data),
    }
  }
}
</script>

<template>

</template>

<style scoped>

</style>