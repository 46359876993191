import {ElementPlus, elementCS,
	createApp, naja,
	netteForms, DriverDetail} from './core.js';

import './Css/styly.scss';

//COMPONENTS
import TicketRegistrationForm from "./Forms/Registration/TicketRegistrationForm.vue";
import OrderDetail from "./Components/Order/Detail/OrderDetail.vue";
import SimpleMap from "./Components/Gallery/SimpleMap/SimpleMap.vue";
import SimpleMap2 from "./Components/Gallery/SimpleMap/SimpleMap2.vue";
import DriverRegistrationForm from "./Forms/Registration/DriverRegistrationForm.vue";
import StateTimeline from "./Components/Registrations/Driver/StateTimeline.vue";
import DriversClubWrapper from "./Components/Registrations/DriversClub/DriversClubWrapper.vue";

import {onMounted} from "vue";

export const app = createApp({
	setup() {
		onMounted(() => {
			window.Nette = netteForms;
			naja.initialize.bind(naja);
			netteForms.initOnLoad();
		});

		return {
		}
	}
})
	.component(TicketRegistrationForm.name, TicketRegistrationForm)
	.component(OrderDetail.name, OrderDetail)
	.component(SimpleMap.name, SimpleMap)
	.component(DriverRegistrationForm.name, DriverRegistrationForm)
	.component(DriverDetail.name, DriverDetail)
	.component(StateTimeline.name, StateTimeline)
	.component(DriversClubWrapper.name, DriversClubWrapper)
	.component(SimpleMap2.name, SimpleMap2)
	.use(ElementPlus, { locale: elementCS })
	.mount('#app');